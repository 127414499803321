<template>
  <div class="body">
    <div> 
      <!-- header / date range for specific planner code -->
      <div>
        <v-container>
          <v-toolbar  flat dense>
            <!-- --------------------------------------------------------------------------------------- -->
            <v-spacer/>
            <c-date-picker
              class="mr-2"
              append-icon="mdi-calendar"
              :label="$t('plannerCodeRange.fromDate')"
              dense outlined
              @input="getPlannerCode()"
              v-model="plannerCodeRange.fromDate"
              hide-details="auto"
            ></c-date-picker> 

            <c-date-picker
              class="mr-2"
              append-icon="mdi-calendar"
              :label="$t('plannerCodeRange.toDate')"
              dense
              outlined
              :max="monthRestriction"
              @input="getPlannerCode()"
              v-model="plannerCodeRange.toDate"
              hide-details="auto"
            ></c-date-picker>
            <!-- --------------------------------------------------------------------------------------- -->
            <v-autocomplete
                class="mr-2"
                v-if="user.User_Category == 'admin' || user.User_Category == 'GC Admin'" 
                dense
                outlined 
                hide-details="auto"
                :loading="SalesLoading"
                :items="SalesOfficeArr"
                style="max-width: 25%"
                v-model="SO"
                :label="$t('PlannersInfo.SalesOfficeName')"
                item-text="SalesOfficeData"
                item-code="SalesOfficeData"
                return-object
                @change="getPlannerData()"
              ></v-autocomplete>
            <!-- --------------------------------------------------------------------------------------- -->
            <v-autocomplete
                class="mr-2"
                dense
                outlined 
                maxlength="5"
                :loading="plannersLoading"
                :items="planners"
                item-code="EmployeesData"
                hide-details="auto"
                item-text="EmployeesData"
                return-object
                :label="$t('plannerCodeRange.PlannerCode')"
                v-model="plannerCodeRange.PlannerCode"
                style="max-width: 20%"
                @change="tempArr.length > 0 ? specificPlanner(plannerCodeRange) : ''"
            ></v-autocomplete>
            <!-- --------------------------------------------------------------------------------------- -->
            <v-btn
            class="mr-5"
            @click="specificPlanner(plannerCodeRange)"
            xxl
            :style="{ color: 'white' }"
            Length="5"
            :disabled="loading || !plannerCodeRange.PlannerCode.EmployeesData"
            color="#0D47A1"
            >  {{$t(`generate.generate`)}} </v-btn >
            <!-- --------------------------------------------------------------------------------------- -->
            <v-tooltip bottom color="primary" content-class="white--text">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                v-bind="attrs"  v-on="on"
                @click="pdfFNC()"
                v-if="tempArr.length > 0"
                xxl :style="{ color: 'white' }" color="#0D47A1"
                >{{ $t(`generate.generate`) }}  <v-icon>mdi-file-download-outline</v-icon>
              </v-btn >
              </template>
            <span>{{$t(`generate.generate`)}}</span>
            </v-tooltip>
            <!-- --------------------------------------------------------------------------------------- -->
            <v-spacer/>
          </v-toolbar>
        </v-container>
      </div>
      <!-- ------------------------------BODY of Information---------------------------------------- -->
      <div class="pl-8 pr-8">
        <v-card flat v-if="tempArr.length > 0">
            <!--*******************************************************************************************************************-->
          <v-radio-group row  dense hide-details="auto" v-model="PeriodOfTime">
            <div v-for="(item,x) in timePeriod" :key="x">
              <v-radio :label="$t(`timePeriod.${item}`)" :value="item" ></v-radio>
            </div>
          </v-radio-group>
        </v-card>
      </div>
            <!--*******************************************************************************************************************-->
      <div class="pl-8 pr-8">
        <v-card class="mt-2" flat style="border: 1px solid black;" v-if="tempArr.length > 0">
          <v-simple-table dense>
            <thead>
              <tr>
                <th
                  style="background-color: #01579b; color: white"
                  class="text-center TableStyle"
                >
                {{ $t(`PlannersInfo.SalesOfficeName`)}}
                </th>
                <th
                  style="background-color: #01579b; color: white"
                  class="text-center TableStyle"
                >
                {{ $t(`PlannersInfo.BranchName`)}}
                </th>
                <th
                  style="background-color: #01579b; color: white"
                  class="text-center TableStyle"
                >
                {{ $t(`PlannersInfo.PlannersName`)}}
                </th>
              </tr>
            </thead>
            <tbody style="background-color: #e8eaf6">
              <tr >
                <td class="text-center TableStyle">{{ EmpInfo.SalesOfficeName }}</td>
                <td class="text-center TableStyle">{{ EmpInfo.BranchName }}</td>
                <td class="text-center TableStyle">{{ EmpInfo.EmployeeName }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </div>
        <!--*******************************************************************************************************************-->
        <!--*******************************************************************************************************************-->
  
        <!--*******************************************************************************************************************-->
        <!--*******************************************************************************************************************-->
            <!--*******************************************************************************************************************-->
      <div class="pl-8 pr-8">
        <v-card class="mt-2" flat style="border: 1px solid black;" v-if="tempArr.length > 0">
          <v-card v-if="PeriodOfTime=='Monthly'" >
            <v-simple-table dense v-if="yearHeader1.length > 0">
              <thead>
                <tr>
                  <th style="background-color: #01579b; color: white" width="20%" class="text-center TableStyle" >
                    {{$t('rankingHeader.Year')}}
                  </th>
                  <th class="text-center TableStyle" v-for="(item, i) in yearHeader1" :key="i" :colSpan="item.months.length" style="background-color: #01579b; color: white" >
                    {{ item.year }}
                  </th>
                  <th class="text-center TableStyle" rowspan="2" colspan="2"  style="background-color: #01579b; color: white" >
                  {{$t('content.total')}}
                  </th>
                </tr>
                <tr>
                  <th style="background-color: #01579b; color: white" width="20%" class="text-center TableStyle" >
                  {{$t('rankingHeader.Month')}}
                  </th>
                  <th class="text-center TableStyle" style="background-color: #01579b; color: white" v-for="(item, i) in monthHeader2" :key="i">
                    {{ item + "月" }}
                  </th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="(item,i) in plannersBody" :key="i"   >
                  <template v-if="i != 4 && i!=6 ">
                    <td :style="item2 && i==6 && item2.rank > 100 ? 'color:red;':''" 
                      class="text-center TableStyle pa-0" v-for="(item2,x) in item" :key="x">
                      {{`${item2 && x==0 ? $t('rankingHeader.'+item2) : item2 &&(i==4 || i==6)  && x !=0 ? item2.rank+'/'+item2.ranking : !item2 && (i==4||i==6)?'-':item2} `}}
                    </td>
                    <!-- :style="i > 2 ? 'background-color: gray;' :''" -->
                    <td  colspan="2"  class="text-center TableStyle pa-0"  >
                      <!-- {{`${i==0 ? total.TotalKakou : i==1 ? total.TotalTH: i==2? total.TotalAvg: i==3?total.TotalSalesAvg :i==5  ? total.TotalNationAvg:'' }`}} -->
                      {{`${i==0 ? total.TotalKakou : i==1 ? total.TotalTH: i==2? '-': i==3?'-' :i==5  ? '-':'-' }`}}
                    </td>
                  </template>
                  <!-- <td style="border-style:none;background-color:white;" ></td> -->
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <!-- ************************QUARTERLY TABLE HEADER************************************** -->
          <v-card  v-else-if="PeriodOfTime=='Quarterly'" >
            <v-simple-table dense>
              <thead>
                <tr>
                  <th style="background-color: #01579b; color: white" width="20%" class="text-center TableStyle" >
                  {{$t('rankingHeader.Year')}}
                  </th>
                  <th class="text-center TableStyle" colSpan="4" style="background-color: #01579b; color: white" >
                    {{ QuarterData.Year }}
                  </th>
                  <th class="text-center TableStyle" rowspan="2" colspan="2"  style="background-color: #01579b; color: white" >
                  {{$t('content.total')}}
                  </th>
                </tr>
                <tr>
                  <th style="background-color: #01579b; color: white" width="20%" class="text-center TableStyle" >
                  {{$t('rankingHeader.Month')}}
                  </th>
                  <th class="text-center TableStyle" style="background-color: #01579b; color: white" v-for="(item, i) in QuarterData.header" :key="i">
                    {{ item  }}
                  </th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="(item,i) in QuarterData.body" :key="i"   >
                    <td :style="item2 && i==6 && item2.rank > 100 ? 'color:red;':''" 
                      class="text-center TableStyle pa-0" v-for="(item2,x) in item" :key="x">
                      {{`${item2 && x==0 ? $t('rankingHeader.'+item2) : item2 } `}}
                    </td>
                    <td  colspan="2"  class="text-center TableStyle pa-0"  >
                      {{`${i==0 ? QuarterData.totalQrtly.TotalKakou : i==1 ?  QuarterData.totalQrtly.TotalTH: i==2?  QuarterData.totalQrtly.TotalAvg:i==3 ? QuarterData.totalQrtly.TotalSalesAvg :i==4  ?  QuarterData.totalQrtly.TotalNationAvg:'' }`}}
                    </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <!-- ************************HALF YEAR TABLE HEADER************************************** -->
          <v-card v-else-if="PeriodOfTime=='HalfYear'">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th style="background-color: #01579b; color: white" width="20%" class="text-center TableStyle" >
                  {{$t('rankingHeader.Year')}}
                  </th>
                  <th class="text-center TableStyle" colSpan="2" style="background-color: #01579b; color: white" >
                    {{ HalfyearData.Year}}
                  </th>
                  <th class="text-center TableStyle" rowspan="2" colspan="2"  style="background-color: #01579b; color: white" >
                  {{$t('content.total')}}
                  </th>
                </tr>
                <tr>
                  <th style="background-color: #01579b; color: white" width="20%" class="text-center TableStyle" >
                  {{$t('rankingHeader.Month')}}
                  </th>
                  <th class="text-center TableStyle" style="background-color: #01579b; color: white" v-for="(item, i) in HalfyearData.header1" :key="i">
                    {{ item  }}
                  </th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="(item,i) in HalfyearData.body1" :key="i"   >
                    <td :style="item2 && i==6 && item2.rank > 100 ? 'color:red;':''" 
                      class="text-center TableStyle pa-0" v-for="(item2,x) in item" :key="x">
                      {{`${item2 && x==0 ? $t('rankingHeader.'+item2) : item2 } `}}
                    </td>
                    <td  colspan="2"  class="text-center TableStyle pa-0"  >
                      {{`${i==0 ? HalfyearData.totalQrtly1.TotalKakou : i==1 ?  HalfyearData.totalQrtly1.TotalTH: i==2?  HalfyearData.totalQrtly1.TotalAvg:i==3?  HalfyearData.totalQrtly1.TotalSalesAvg :i==4  ?  HalfyearData.totalQrtly1.TotalNationAvg:'' }`}}
                    </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-card>
      </div>
        <!--*******************************************************************************************************************-->
        <!--*******************************************************************************************************************-->
        <!--*******************************************************************************************************************-->
      <div class="pl-8 pr-8">
        <v-card class="mt-2" flat style="border: 1px solid black;" v-if="tempArr.length > 0">
        <v-card v-if="PeriodOfTime=='Monthly'" >
          <v-simple-table  dense> 
            <thead>
              <tr>
                <th style="background-color: #01579b; color: white" rowSpan="2" width="20%" class="text-center TableStyle">
                {{$t('content.contentTH')}}
                </th>
                <th class="text-center TableStyle" v-for="(item, i) in yearHeader" :key="i" :colSpan="item.months.length" style="background-color: #01579b; color: white">
                  {{ item.year }}
                </th>
                <!-- colspan="2" rowSpan="2" style="background-color: #01579b; color: white"  -->
                <th class="text-center TableStyle" colspan="2" rowspan="2"  style="background-color: #01579b; color: white"  >
                  {{$t('content.total')}}
                  <v-icon v-if="sortingdata==false"  dark @click="sorting()" >mdi-arrow-up</v-icon>
                  <v-icon v-else dark @click="sorting2()" >mdi-arrow-down</v-icon>
                </th>
              </tr>
              <tr>
                <th class="text-center TableStyle" style="background-color: #01579b; color: white" v-for="(item, i) in monthHeader" :key="i">
                  {{ item + "月" }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in tempArr" :key="i" >
                <template v-if="item.targetKomoku !=null">
                  <td  class="text-center TableStyle" >
                    {{ item.targetKomoku }}
                  </td>
                  <td v-for="(data, index) in monthHeader" :key="index" class="text-center TableStyle" >
                    <v-tooltip right content-class="white--text">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-if="item.count[index] != undefined" v-bind="attrs"  v-on="on" >
                          {{ item.count[index].count }}
                        </span>
                      </template>
                      <span v-if="item.count[index] != undefined">
                        <tr v-for="(chng, x) in item.count[index].changeAfter" :key="x">
                          <td>{{ chng }}</td>
                        </tr>
                      </span>
                    </v-tooltip>
                  </td>
                  <td class="text-center TableStyle">
                    <v-tooltip right content-class="white--text">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ item.total.total }}
                        </span>
                      </template>
                      <span>
                        <tr v-for="(chng, x) in item.total.changeAfter" :key="x">
                          <td>{{ chng }}</td>
                        </tr>
                      </span>
                    </v-tooltip>
                  </td>
                  <td class="TableStyle pa-0" style="width:1%">
                    <center>
                      <v-checkbox dense hide-details="auto" class="ml-2" v-model="item.isBool"/>
                    </center>
                  </td>
                </template>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
        <!--*******************************************************************************************************************-->
        <v-card v-else-if="PeriodOfTime=='Quarterly'">
          <v-card>
            <v-simple-table  dense> 
              <thead >
                <tr>
                  <th style="background-color: #01579b; color: white" width="20%"  rowSpan="2" class="text-center TableStyle" >
                    {{$t('content.contentTH')}}
                  </th>
                  <th class="text-center TableStyle" colSpan="4" style="background-color: #01579b; color: white" >
                    {{ QuarterData.Year }}
                  </th>
                  <th class="text-center TableStyle" colspan="2"  rowspan="2"  style="background-color: #01579b; color: white" >
                  {{$t('content.total')}}
                    <v-icon v-if="sortingdata==false"  dark @click="sorting()">mdi-arrow-up</v-icon>
                    <v-icon v-else dark @click="sorting2()">mdi-arrow-down</v-icon> 
                  </th>
                </tr>
                <tr>
                  <th class="text-center TableStyle" style="background-color: #01579b; color: white" v-for="(item, i) in QuarterData.header" :key="i">
                    {{ item  }}
                  </th>
                </tr>
                </thead>
              <tbody>
                <tr v-for="(item, i) in QuarterData.newArr" :key="i" >
                  <template v-if="item.targetKomoku !=null">
                    <td  class="text-center TableStyle" >
                      {{ item.targetKomoku }}
                    </td>
                    <td v-for="(data, index) in QuarterData.header" :key="index" class="text-center TableStyle" >
                      <v-tooltip right content-class="white--text">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-if="item.test[index][0] != undefined" v-bind="attrs"  v-on="on" >
                            {{ item.test[index][0].count }}
                          </span>
                        </template>
                        <span v-if="item.test[index][0] != undefined">
                          <tr v-for="(chng, x) in item.test[index][0].changeAfter" :key="x">
                            <td>{{ chng }}</td>
                          </tr>
                        </span>
                      </v-tooltip>
                    </td>
                    <td class="text-center TableStyle">
                      <v-tooltip right content-class="white--text">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ item.total.total }}
                          </span>
                        </template>
                        <span>
                          <tr v-for="(chng, x) in item.total.changeAfter" :key="x">
                            <td>{{ chng }}</td>
                          </tr>
                        </span>
                      </v-tooltip>
                    </td>
                    <td class="TableStyle pa-0" style="width:1%">
                      <center>
                        <v-checkbox dense hide-details="auto" class="ml-2" v-model="item.isBool"/>
                      </center>
                    </td>
                  </template>
                </tr>
              </tbody> 
            </v-simple-table>
          </v-card>
        </v-card>
        <!--*******************************************************************************************************************-->
        <div v-else-if="PeriodOfTime=='HalfYear'">
          <v-card>
            <v-simple-table  dense> 
              <thead >
                <tr>
                  <th style="background-color: #01579b; color: white" width="20%"  rowSpan="2" class="text-center TableStyle" >
                    {{$t('content.contentTH')}}
                  </th>
                  <th class="text-center TableStyle" colSpan="2" style="background-color: #01579b; color: white" >
                    {{HalfyearData.Year}}
                  </th>
                  <th class="text-center TableStyle" colspan="2"  rowspan="2"  style="background-color: #01579b; color: white" >
                    {{$t('content.total')}}
                    <v-icon v-if="sortingdata==false"  dark @click="sorting()">mdi-arrow-up</v-icon>
                    <v-icon v-else dark @click="sorting2()">mdi-arrow-down</v-icon> 
                  </th>
                </tr>
                <tr>
                  <th class="text-center TableStyle" style="background-color: #01579b; color: white" v-for="(item, i) in HalfyearData.header1" :key="i">
                    {{ item  }}
                  </th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="(item, i) in HalfyearData.newArr1" :key="i" >
                <template v-if="item.targetKomoku !=null">
                  <td  class="text-center TableStyle" >
                    {{ item.targetKomoku }}
                  </td>
                  <td v-for="(data, index) in HalfyearData.header1" :key="index" class="text-center TableStyle" >
                    <v-tooltip right content-class="white--text">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-if="item.test2[index][0] != undefined" v-bind="attrs"  v-on="on" >
                          {{ item.test2[index][0].count }}
                        </span>
                      </template>
                      <span v-if="item.test2[index][0] != undefined">
                        <tr v-for="(chng, x) in item.test2[index][0].changeAfter" :key="x">
                          <td>{{ chng }}</td>
                        </tr>
                      </span>
                    </v-tooltip>
                  </td>
                  <td class="text-center TableStyle">
                    <v-tooltip right content-class="white--text">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ item.total.total }}
                        </span>
                      </template>
                      <span>
                        <tr v-for="(chng, x) in item.total.changeAfter" :key="x">
                          <td>{{ chng }}</td>
                        </tr>
                      </span>
                    </v-tooltip>
                  </td>
                  <td class="TableStyle pa-0" style="width:1%">
                    <center>
                        <v-checkbox dense hide-details="auto" class="ml-2" v-model="item.isBool"/>
                    </center>
                  </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </div>
        </v-card>
      </div>
        <!--*******************************************************************************************************************-->
        <!--*******************************************************************************************************************-->
        <!--*******************************************************************************************************************-->
      <div class="pa-8">
        <v-card class="mt-n6" style="border: 2px solid black;" v-if="tempArr.length > 0">
          <v-card  class="pa-2" outlined  v-if="PeriodOfTime=='Monthly'">
              <column-chart-mnth  :items="{yearHeader:yearHeader,monthHeader:monthHeader2,plannersBody:plannersBody,total:total}" />
          </v-card>
          <v-card class="pa-2" outlined v-else-if="PeriodOfTime=='Quarterly'">
              <colum-chart :items="QuarterData"/>
          </v-card>
          <v-card  class="pa-2" outlined v-else-if="PeriodOfTime=='HalfYear'">
              <colum-chart-hlf :items="HalfyearData" />
          </v-card>
          <v-card class="pa-1">
            <bar-chart
              chart-id="chart"
              :data="tempArr"
              :height="500"
            ></bar-chart>
          </v-card>
        </v-card>
      </div>
    </div>
    <div v-if="!loading && tempArr.length == 0">
        <Logo />
    </div>
    <div v-else class="center" >
      <v-overlay :value="loading" color="#000000">
        <v-progress-circular
          :width="10"
          color="primary"
          indeterminate
          :size="70"
        >
        </v-progress-circular>
        <div>{{'ローディング中....'}}</div> 
      </v-overlay>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import CDatePicker from "../components/CDatePicker.vue";
import BarChart from "../components/Chart/BarChart.vue";
import print from "../components/PdfPrint/print.js";
import Swal from "sweetalert2";
import moment from 'moment'
import THdata from '../assets/class/THData/index'
import SalesOfficeClass from '../assets/class/salesOffice'
import PlannerCodeClass from '../assets/class/PlannersCode'
import Quarterly1 from '../assets/class/THData/quaterly'
import HalfYear from '../assets/class/THData/halfYear'
import ColumChart from '../components/Chart/ColumChart.vue';
import ColumChartHlf from '../components/Chart/ColumChartHlf.vue';
import ColumnChartMnth from '../components/Chart/ColumnChartMnth.vue';
import Logo from "../components/Logo.vue";
export default {
  components: { CDatePicker, BarChart, ColumChart, ColumChartHlf, ColumnChartMnth, Logo },
  data() {
    return {
      thdata: new THdata(),
      salesOfficefunction: new SalesOfficeClass(),
      plannersCodefunction: new PlannerCodeClass(),
      quarterlyfunction: new Quarterly1(),
      halfYearfunction: new HalfYear(),
      magic: false,
      loading: false,
      datePicker: {
        fromDate: "",
        toDate: "",
        PlannerCode: "",
      },
      plannerCodeRange: {
        fromDate: moment().subtract(365,'days').format('YYYY-MM-DD'),
        // fromDate: moment().subtract(11,'months').startOf('month').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        PlannerCode:{},
      },
      sortingdata:false,
      user:{},
      yearHeader: [],
      monthHeader: [],
      databody: [],
      tempArr: [],
      checkboxArr: [],
      EmpInfo: {},
      plannerList: [],
      test1: "",
      planners:[],
      plannersLoading:false,
      SalesOfficeArr:[],
      saleOfficeLoding:false,
      SO:'',
      yearHeader1:[],
      monthHeader2:[],
      plannerArr:[],
      salesArr:[],
      nationwideArr:[],
      plannersBody :[
          ['Kakou Irai'],
          ['No of TH'],
          ['Average No of TH'],
          ['Average SalesOffice'],
          ['Ranking of SalesOffice'],
          ['Average Nationwide'],
          ['Ranking of Nationwide'],
      ],
      switch1:false,
      QuarterBody:[],
      QuarterData:{},
      HalfYearBody:[],
      HalfyearData:{},
      total:{
        TotalKakou:0,
        TotalTH:0,
        TotalAvg:0,
        TotalSalesAvg:0,
        TotalNationAvg:0
      },
      timePeriod:['Monthly','Quarterly','HalfYear'],
      PeriodOfTime:'Monthly',
      tempData:{
        avg: 0,item: [],kakou: 0,th: 0
      }

    };
  },
  methods: {
    loadData() {
      if( parseInt(moment.duration(moment(this.plannerCodeRange.toDate,"YYYY-MM-DD").diff(moment(this.plannerCodeRange.fromDate,"YYYY-MM-DD"))).asDays()) <= 365   ){
        // this.EmpInfo={}
        this.EmpInfo=this.datePicker
        this.tempArr=[]
        this.loading = true;
        this.PeriodOfTime = 'Monthly'
        this.nextLoadData()
        // setTimeout(() => {
        //       axios.post(`${this.aws}getPLannerInfo`, this.datePicker,this.apiKey).then((res) => {
        //     // axios.post(`${this.api}getPLannerInfo`, this.datePicker).then((res) => {
        //       if(res.data.length > 0){
        //           this.EmpInfo={}
        //           this.EmpInfo=res.data[0]
        //           this.EmpInfo.fromDate =this.datePicker.fromDate
        //           this.EmpInfo.toDate =this.datePicker.toDate
        //           this.nextLoadData()
        //       }else{
        //         Swal.fire({
        //           icon: "error",
        //           title: `${this.$i18n.locale =='ja'?'エラー' :'Error' }`,
        //           text: `${this.$i18n.locale =='ja'?'営業所名もしくは設計担当者コードの選択がされておりません。 1' :'The sales office name or designer code has not been selected!'}`,
        //         });
        //         this.loading=false
        //       }
        //     }).catch(err=>{
        //       if(err.response.status == 403 || err.response.status == 401){
        //         Swal.fire({
        //           icon: "info",
        //           title: `Token Expired System will forced logout`,
        //           showConfirmButton: true,
        //         }).then((result) => {
        //           if (result.isConfirmed) {
        //           this.$store.commit("STORE_USERINFO", null);
        //           this.$store.commit("STORE_SALESOFFICE", null)
        //           this.$store.commit("STORE_CHART_URL", null);
        //           this.$router.push("/login");
        //           }
        //         });
        //       }else{
        //         Swal.fire({
        //             icon: "error",
        //             title: `${this.$i18n.locale =='ja'?'エラー' :'Error' }`,
        //             text: `${err.message}`,
        //           });
        //         this.loading=false
        //         return false;
        //       }
        //     })
        //     //---------------------------------------------------------------------------------------
        // }, 1000);
        
      }else{
        Swal.fire({
          icon: "warning",
          title: `${this.$i18n.locale =='ja'?'エラー' :'Warning' }`,
          text: `${this.$i18n.locale =='ja'?' 開始日から終了日までの期間が1年以内の期間になるように入力し直してください。' :'Please re-enter so that the period from the start date to the end date is within one year.!'}`,
        });
        this.loading=false;
        return false;
      }
    },
    nextLoadData(){
          this.plannersBody =[
              ['Kakou Irai'],
              ['No of TH'],
              ['Average No of TH'],
              ['Average SalesOffice'],
              ['Ranking of SalesOffice'],
              ['Average Nationwide'],
              ['Ranking of Nationwide']
          ]
          this.yearHeader1=[]
          this.monthHeader2=[]
          this.plannerArr=[]
          this.salesArr=[]
          this.nationwideArr=[]
          this.TotalKakou=0
          this.TotalTH=0
          this.total={
              TotalKakou:0,
              TotalTH:0,
              TotalAvg:0,
              TotalSalesAvg:0,
              TotalNationAvg:0
            }
          this.EmpInfo.userCategory = this.userInfo.User_Category
          // this.thdata.getTHdata(this.EmpInfo,this.api,{}).then(data=>{
          this.thdata.getTHdata(this.EmpInfo,this.aws,this.apiKey).then(data=>{
            // console.log(data);
            let startMonth = moment(this.plannerCodeRange.fromDate).format('MM')
            let startYear = moment(this.plannerCodeRange.fromDate).format('YYYY')
            let endMonth = moment(this.plannerCodeRange.toDate).format('MM')
            let endYear = moment(this.plannerCodeRange.toDate).format('YYYY')
            let tempData = this.tempData
            if(data.headers[0].months[0] != startMonth){
              data.headers[0].months.unshift(startMonth)
              if(!data.headers[0].year){
                data.headers[0].year.push(startYear)
              }
            }
            if(data.headers[1].months[data.headers[1].months.length-1] != endMonth){
              data.headers[1].months.push(endMonth)
              if(!data.headers[1].year){
                data.headers[1].year.push(endYear)
              }
            }
            if(data.headers[0].months[0] != startMonth && data.headers[1].months[data.headers[1].months.length-1] != endMonth){
              data.headers[0].months.unshift(startMonth)
              if(!data.headers[0].year){
                data.headers[0].year.push(startYear)
              }
              data.headers[1].months.push(endMonth)
              if(!data.headers[1].year){
                data.headers[1].year.push(endYear)
              }
            }
            this.yearHeader1 = data.headers;


            this.monthHeader2 = data.headers.map((r) => {
                return r.months;
              });
            this.monthHeader2 = this.monthHeader2.flat();


            if(data.planner[0].month != startMonth){
            data.planner.unshift({
              EmployeeCode: data.planner[0].EmployeeCode,
              month: startMonth, year: startYear,...tempData
            })
            }
            if(data.planner[data.planner.length-1].month != endMonth){
            data.planner.push({
              EmployeeCode: data.planner[0].EmployeeCode,
              month: endMonth, year: endYear,...tempData
            })
            }
            if(data.planner[0].month != startMonth && data.planner[data.planner.length-1].month != endMonth){
            data.planner.unshift({
              EmployeeCode: data.planner[0].EmployeeCode,
              month: startMonth, year: startYear,...tempData
            })
            data.planner.push({
              EmployeeCode: data.planner[0].EmployeeCode,
              month: endMonth, year: endYear,...tempData
            })
            }
            this.plannerArr =data.planner
            this.salesArr =data.SalesOffice
            //  console.log(this.salesArr,'salesArr');
            this.nationwideArr =data.NationWide
              this.yearHeader1.forEach(r=>{
                r.months.forEach(rr=>{
                    let index =this.plannerArr.findIndex(x=> x.year == r.year && x.month == rr)
                    if(index > -1){
                      this.plannersBody[0].push(this.plannerArr[index].kakou)
                      this.plannersBody[1].push(this.plannerArr[index].th)
                      this.plannersBody[2].push(this.plannerArr[index].avg)
                    }else{
                      this.plannersBody[0].push(0)
                      this.plannersBody[1].push(0)
                      this.plannersBody[2].push(0)
                    }
                  // ---------------------------------------------
                    let index2=this.salesArr.findIndex(y=> y.year == r.year && y.month == rr)
                    if(index2 > -1){
                      this.plannersBody[3].push(this.salesArr[index2].avg)
                      this.plannersBody[4].push(this.salesArr[index2].rank ? {rank:this.salesArr[index2].rank ,ranking: this.salesArr[index2].ranking.length} : 0)
                    }
                    else{
                      this.plannersBody[3].push(0)
                      this.plannersBody[4].push(0)  
                    } 
                    // ---------------------------------------------
                  
                    let index3 = this.nationwideArr.findIndex(y=> y.year == r.year && y.month == rr)
                    if(index3 > -1){
                      this.plannersBody[5].push(this.nationwideArr[index3].avg)
                      this.plannersBody[6].push(this.nationwideArr[index3].rank ? { rank:this.nationwideArr[index3].rank ,ranking: this.nationwideArr[index3].ranking.length} :0)
                    }
                    else{
                      this.plannersBody[5].push(0)
                      this.plannersBody[6].push(0)
                    }
                })
              })
              this.plannersBody[0].forEach((total,index)=>{
                if(index!=0){
                  this.total.TotalKakou +=total ? total :0
                }
              })
              this.plannersBody[1].forEach((total,index)=>{
                if(index!=0){
                  this.total.TotalTH +=total ? total :0
                }
              })

              this.total.TotalAvg = (this.total.TotalTH / this.total.TotalKakou).toFixed(2)
              this.plannersBody[3].forEach((total,index)=>{
                if(index!=0 && index!=13){
                  this.total.TotalSalesAvg += total ? total :0
                }
              })

              this.total.TotalSalesAvg =(this.total.TotalSalesAvg/12).toFixed(2)


              
              this.plannersBody[5].forEach((total,index)=>{
                if(index!=0 && index!=13){
                  this.total.TotalNationAvg += total ? total :0
                }
              })
              this.total.TotalNationAvg =(this.total.TotalNationAvg/12).toFixed(2)
//=======================================,'sales'==========================================================

        this.yearHeader = [],
        this.monthHeader = [],
        this.databody = [],
        this.tempArr = [];
        // axios.post(`${this.api}newTH`, this.datePicker).then((res) => {
          axios.post(`${this.aws}newTH`, this.datePicker,this.apiKey).then((res) => {
              if (res.data[0].headers.length > 0 && res.data[0].tableData.length ) {
                this.yearHeader=this.yearHeader1
                this.monthHeader=this.monthHeader2
                this.databody = res.data[0].tableData;
                this.databody.forEach((r) => {
                  let tempCounts = [];
                  this.yearHeader.forEach((y) => {
                    y.months.forEach((mnth) => {
                      let index = r.count.findIndex( (c) => c.year == y.year && c.month == mnth);
                      if (index > -1) tempCounts.push(r.count[index]);
                      else tempCounts.push(0);
                    });
                  });
                  this.tempArr.push({
                    targetKomoku: r.targetKomoku,
                    count: tempCounts,
                    total: this.countFnc(tempCounts),
                    infor: r.count,
                  });
                    this.tempArr.sort((a, b) => {
                      return b.total.total - a.total.total;
                    });
                });

                this.quarterlyfunction.getQuarterly(this.yearHeader,this.monthHeader2,this.plannersBody,this.salesArr,this.nationwideArr,this.tempArr).then((data)=>{
                  this.QuarterData = {...data };
                })

                this.halfYearfunction.getHalfYear(this.yearHeader,this.monthHeader2,this.plannersBody,this.salesArr,this.nationwideArr,this.tempArr).then(data=>{
                  this.HalfyearData={...data };
                })
                    
                this.loading = false; 
                this.magic = false;
              } 
              else{
                Swal.fire({
                  icon: "error",
                  title: `${this.$i18n.locale =='ja'?'エラー' :'Error' }`,
                  text: `No Plan, No Kakouirai`,
                });
                this.loading = false;
              }
            });
          })
          // console.log(this.plannersBody);
    },
    getPlannerCode() {
      if( parseInt(moment.duration(moment(this.plannerCodeRange.toDate,"YYYY-MM-DD").diff(moment(this.plannerCodeRange.fromDate,"YYYY-MM-DD"))).asDays()) <= 365   ){
        this.user={}
        this.user=this.userInfo 
        if(this.userInfo.User_Category != 'admin' && this.userInfo.User_Category != 'GC Admin'){
          this.planners=[]
          this.plannerCodeRange.PlannerCode=this.userInfo.Emp_code
          let obj={
            PlannerCode:this.userInfo.Emp_code,
            fromDate:this.plannerCodeRange.fromDate,
            toDate:this.plannerCodeRange.toDate
          }
          this.plannersLoading=true
          this.plannersCodefunction.getplannersCode(this.aws,obj,this.apiKey).then((r)=>{
            // console.log(r);
            this.planners=r.data
            let a = this.planners.findIndex(data=> data.EmployeeCode == obj.PlannerCode)
              if(a == -1){
                Swal.fire({
                  icon: "error",
                  title: "Error!",
                  text: `Planner ${this.userInfo.Emp_code} don't have Data`,
                });
                this.planners.push({
                  EmployeesData:`${this.userInfo.Emp_code +' - '+this.userInfo.Name}`,
                  EmployeeCode:this.userInfo.Emp_code,
                  EmployeeName:this.userInfo.Name
                })
              }
            this.plannersLoading=r.loading
          }) 
        }else{
          this.SalesOfficeArr=[]
          this.planners=[]
          this.SalesLoading=true;
          this.SO=''
          let obj={
            fromDate:this.plannerCodeRange.fromDate,
            toDate:this.plannerCodeRange.toDate,
            userCategory:this.userInfo.User_Category,
            SalesOfficeName:this.userInfo.User_Category
          }
          this.salesOfficefunction.getSalesOffice(obj,this.aws,this.apiKey).then((r)=>{
              this.SalesOfficeArr = r.data.filter((x) => x.EmployeeName !== null).sort((a, b) => a.sort - b.sort);
              this.SalesLoading = r.data.loading;
          })
        }
      }else{
        Swal.fire({
          icon: "warning",
          title: `${this.$i18n.locale =='ja'?'エラー' :'Warning' }`,
          text: `${this.$i18n.locale =='ja'?' 開始日から終了日までの期間が1年以内の期間になるように入力し直してください。' :'Please re-enter so that the period from the start date to the end date is within one year.!'}`,
        });
        return false
      }
    },
    specificPlanner(item) {
    this.tempArr = []
    if(item.PlannerCode.SalesOfficeCode == undefined ){
      Swal.fire({
              icon: "error",
              title: "Error!",
              text: `Planner ${this.userInfo.Emp_code} dont have Data`,
            });
      return false
    }

      this.datePicker = {
        fromDate:item.fromDate,
        toDate:item.toDate,
        BranchCode:item.PlannerCode.ContractExhibitionCode,
        BranchName:item.PlannerCode.ContractExhibitionName,
        PlannerCode:item.PlannerCode.EmployeeCode,
        EmployeeCode:item.PlannerCode.EmployeeCode,
        EmployeeName:item.PlannerCode.EmployeeName,
        SalesOfficeCode:item.PlannerCode.SalesOfficeCode,
        SalesOfficeName:item.PlannerCode.SalesOfficeName
      };
        this.loadData();
    },
    // specificPlanner(item) {
    //   this.tempArr = []
    //   this.datePicker = {
    //     fromDate:item.fromDate,
    //     toDate:item.toDate,
    //     PlannerCode:item.PlannerCode.EmployeeCode
    //   };
    //   this.magic=false
    //   this.loadData();
    // },
    countFnc(count) {
      let total = 0;
      let obj = {};
      let changeAfters = [];
      count.forEach((rec) => {
        if (rec.count) {
          total += parseInt(rec.count);
          rec.changeAfter.filter((rec) => {
            changeAfters.push(rec);
          });
          obj = {
            total: total,
            changeAfter: changeAfters,
          };
        }
      });
      return obj;
    },
    pdfFNC() {
      let lang = this.$i18n.locale
      let Chart = this.$store.state.chartUrl;
      var test = new print();
      test.print(this.yearHeader, Chart, this.tempArr, this.EmpInfo,lang,this.yearHeader1,this.plannersBody,this.total);
    },
    sorting(){
      this.sortingdata=true
        this.tempArr.sort((a, b) => {
                    return a.total.total - b.total.total;
                  });
    },
    sorting2(){
      this.sortingdata=false
        this.tempArr.sort((a, b) => {
                    return b.total.total - a.total.total;
                  });
    },
    getPlannerData(){
      this.planners=[]
      this.tempArr=[]
      this.plannerCodeRange.PlannerCode.EmployeesData=''
      this.planners = this.SalesOfficeArr.filter(r=>{
            if( r.SalesOfficeName == this.SO.SalesOfficeName){
                r.EmployeesData = `${r.EmployeeCode +' - ' +r.EmployeeName}`
              return r
            }
          
      })
    },
  },
  mounted() {
    this.apiKey.headers.Authorization = this.userInfo.Token
    setTimeout(() => {
      this.getPlannerCode()
      this.$store.commit("STORE_CHART_URL", null);
    }, 1000);

  },
computed: {
    monthRestriction() {
      return moment(this.plannerCodeRange.fromDate).add(12,'months').endOf('month').format('YYYY-MM-DD')
    },
    userInfo() {
      return this.$root.decryptedUser;
    }
  },
};
</script>
<style lang="scss" scoped>
  .TableStyle {
    border: 1px solid black;
  }

  input {
    width: 99%;
    text-align: center;
  }
  .center {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
}
.cnter{
    position:absolute;
    width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

</style>